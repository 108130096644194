import { LandingPageComponent } from './landing-page/landing-page.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StorePromotionsComponent } from './widgets/store-promotions/store-promotions.component';
import { PcPalWidgetComponent } from './widgets/pc-pal-widget/pc-pal-widget.component';
import { PdfViewerComponent } from './widgets/pdf-viewer/pdf-viewer.component';




const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: ':con/:lng', component: HomeComponent },
  { path: 'pcpal', component: PcPalWidgetComponent },
  { path: 'pdf_viewer', component: PdfViewerComponent },
  { path: ':con/:lng/store', loadChildren: () => import("./modules/store-module/store-module.module").then(m => m.StoreModuleModule) },
  { path: 'businessenquiry', loadChildren: () => import("./modules/enquery-module/enquery-module.module").then(m => m.EnqueryModuleModule) },
  { path: 'redeem', loadChildren: () => import("./modules/redeem-module/redeem-module.module").then(m => m.RedeemModuleModule) },
  { path: 'terms', loadChildren: () => import("./modules/terms-module/terms-module.module").then(m => m.TermsModuleModule) },
  { path: ':con/:lng/blogs',loadChildren: () => import("./modules/blog-module/blog-module.module").then(m=>m.BlogModuleModule)},
  { path: 'faq', loadChildren: () => import("./modules/faq-module/faq-module.module").then(m => m.FaqModuleModule) },
  { path: 'payment-status', loadChildren: () => import("./modules/payment-module/payment-module.module").then(m => m.PaymentModuleModule) },
  { path: 'landing-page/:lp', component: LandingPageComponent },
  { path: 'service-centers', component: HomeComponent },
  { path: 'select-country', component: HomeComponent },
  { path: ':con/:lng/store/promo/:slug', component: StorePromotionsComponent },
  // { path: '**', component: PageNotFoundComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
  }
}
