<div style="width: 300px;position: relative;" class="blogs">

</div>
<carousel #carouselTemplate [borderRadius]="20" style="z-index:1;" 
 [autoplayInterval]="interval" 
  [cellsToShow]="1"
  [loop]="true"
  [pauseOnHover]="pauseOnHover"
  [cellWidth]="552"
    [height]="height"  >
    <div #carouselCell class="carousel-cell" *ngFor="let item of bannerImages">
        <img *ngIf="canShowImage(item)"  [src]="item.path"  [alt]="item.alt" style="height: auto; max-height: fit-content; object-fit: cover;">            
        <ng-container *ngIf="canShowVideo(item)">
          <div class="carousel-cell__overlay" (click)="playVideo()"></div>
        <iframe class="iframe" 
            style="position: absolute;z-index: 9999; height: 100% !important; object-fit: cover; width: 100%;top:0; left: 0;
            right: 0;bottom: 0;"
            [src]="transform(item.path)" title="YouTube video player" allow="autoplay"
            scrolling="no"
            frameborder="0" 
            referrerpolicy="strict-origin-when-cross-origin" ></iframe>
   
          </ng-container>  
           </div>
</carousel>

