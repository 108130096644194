import {
  Component, OnInit, ViewChild,
  ElementRef, AfterViewInit, Renderer2,
  HostListener, Input, Output, OnChanges,
  SimpleChange, SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CarouselComponent } from 'angular-responsive-carousel';
declare var $: any;

@Component({
  selector: 'app-apac-offers',
  templateUrl: './apac-offers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./apac-offers.component.css'],
  styles: [`
      ::ng-deep .offer-gallery .carousel-arrow-next{
          right: -20px !important;
      }

      ::ng-deep .offer-gallery .carousel-arrow-prev{
          left: -20px !important;
      }
  `]
})
export class ApacOffersComponent implements OnInit, AfterViewInit {

  @ViewChild("offerGallery") offerGallery!: ElementRef;
  @ViewChild('carouselCell', { static: false }) carouselCell!: ElementRef;
  iframeHeight: string = '100%';

  //images: any[] = [];
  bannersLoaded = 0;
  images: any[] = [
    {
      "src": "https://www.youtube.com/embed/WepYhlI0nns?si=eWVPupwjMvzXjIgM",
      "type": "video"
    },
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/4329.jpg",
      "type": "img"
    },
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/Legion%205i%20Pro_1284x896_300%20Dpi.png",
      "type": "img"
    }
  ]


  @Input('height') height: number = 250;
  @Input('width') width: number = 400;
  @ViewChild("carouselTemplate") carouselTemplate?: CarouselComponent
  bannerImages: any[] = [];
  slider: any;

  constructor(private apiService: ApiService,
    private ref: ChangeDetectorRef,

    private renderer: Renderer2, private domSanitizer: DomSanitizer, public utily: UtilitiesService
  ) { }


  pauseOnHover: boolean = true;
  interval = 5000; //ms 

  ngAfterViewInit() {
    this.ref.detach();
    this.pauseOnHover = window.innerWidth < 768 ? false : true;
    this.handleSilder();
  }

  handleSilder() {
    setInterval(() => {
      let slider: number = this.getCurrentSlide();
      let imageLength = this.images.length - 1;
      if (slider == imageLength) {
         if(!this.isPlaying) this.carouselTemplate?.carousel.prev(slider);
      }else{
        if(!this.isPlaying)  this.carouselTemplate?.carousel.next(slider + 1);
      }
    }, this.interval)
  }

  onIframeLoad(event: any) {
    const carouselCellHeight = this.carouselCell.nativeElement.offsetHeight;
    this.iframeHeight = `${carouselCellHeight}px`;
    let iframe = event.target as HTMLIFrameElement;
    if (iframe != null)
      iframe.style.height = this.iframeHeight;
  }


  ngOnInit(): void {

    console.log("this.images ", this.images);

    this.caroselRender(this.images);
    this.utily.banners.subscribe(val => {
      console.log(val);
      this.images = val;
      $('#thumb-list_holder').html('');
      this.caroselRender(this.images);
      this.ref.detectChanges();

    })

  }

  autoPlay = true;
  isPlaying = false;
  playVideo() {
    if (!this.isPlaying) {
      var videoUrl = $(".iframe").prop('src');
      videoUrl += "&autoplay=1";
      $(".iframe").prop('src', videoUrl);
      this.isPlaying = true;
      this.autoPlay = false;
    } else {
      var videoUrl = $(".iframe").prop('src');
      videoUrl = videoUrl.replace("&autoplay=1", "");
      $(".iframe").prop('src', '');
      $(".iframe").prop('src', videoUrl);
      this.autoPlay = false;
      this.isPlaying = false;
    }
  
  }

  caroselRender(images: any) {
    let counter = 0;
    this.bannerImages = [];
    for (let img of images) {

      this.bannerImages.push({ path: img.src, alt: img.image_alt_text, type: img.type });
      if (img.type == 'video') {

        $('#thumb-list_holder').append(`<li style= "height: 60px;">
        <a href="javascript:" id="offer_thumb_id_`+ (counter++) + `" >
            <img src="/assets/images/video-thumbnail-16-9.png"   width="100%" height="100%" alt="` + img.image_alt_text + `" class="img-fluid" style="height:100%;">
        </a>
        </li>`);
      } else {
        $('#thumb-list_holder').append(`<li style= "height: 60px;">
        <a href="javascript:" id="offer_thumb_id_`+ (counter++) + `" >
            <img src="`+ img.src + `"   width="100%"  alt="` + img.image_alt_text + `" class="img-fluid" style="height:100%;" ">
        </a>
        </li>`);
      }

    }
  }



  canShowImage(item: any) {

    return (item['type'] == 'img');
  }

  canShowVideo(item: any) {

    return (item['type'] == 'video');
  }

  transform(url: any) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showPage(ev: any) {
    if ($(ev.target).hasClass('carousel-arrow')) {
      return;
    }
    const url = this.images[this.getCurrentSlide()].url;
    if (url) {
      window.open(url, '_blank');
    }
  }



  getCurrentSlide(): number {
    return this.carouselTemplate?.slideCounter;
  }

  nextSlide() {
    this.carouselTemplate?.next();
  }

  prevSlide() {
    this.carouselTemplate?.carousel.prev(0);
    console.log("clicked")
  }

}